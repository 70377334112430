import { useSelector } from 'react-redux';
import BookingCalendar from '../../../features/HomePage/bookingCalendar/bookingCalendar';
import { UserData } from '../../../types/user';
import { NextClassContainer } from '../../../features/HomePage/nextClass/nextClass';
import { useUserBookings } from '../../../hooks/useUserBookings';
import { cx } from 'classix';
import { getTheLatestAttendedBooking } from '../../../util/bookings';
import { useEffect, useState } from 'react';
import { CorrectionDocument } from '../../../types/feedback';
import { BookingData } from '../../../firebase/subscription/subscription';
import { getCorrectionDocument } from '../../../firebase/feedback/correction';
import {
  getBookingDocument,
  getUserBookings,
} from '../../../firebase/subscription/bookings/bookings';
import { ClassInsightContainer } from '../../../features/HomePage/insight/classInsightContainer';
import { trackHomePageClassInsight } from '../../../features/HomePage/insight/analytics';
import { RankingContainer } from '../../../features/HomePage/ranking/RankingContainer';
import { NextClassTip } from '../../../features/HomePage/tip/nextClassTip';
import { ProfileOverview } from '../../../features/HomePage/profileOverview/profileOverview';
import ActivityFeedContainer from '../../../components/feed/activityFeed/activityFeedContainer';
import NoBorderTabs from '../../../components/tabs/noBorderTabs/NoBorderTabs';
import { trackHomePageData } from '../analytics';
import { ReferFriendContainer } from '../../../features/HomePage/events/referFriendContainer/referFriendContainer';
import { LearningPathLevel } from '../../../components/learningPath/learningPathLevel/learningPathLevel';
import { LearningPathPersonal } from '../../../types/learningPath';
import { GuestPassesContainer } from '../../../features/HomePage/events/guestPassesContainer/guestPassesContainer';

export const SubscriptionLayout = (props: { setSubmitting: Function }) => {
  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );
  const { bookings, bookingsLoading, bookingsError } = useUserBookings(
    userData?.id,
  );

  const [latestCorrection, setLatestCorrection] = useState<
    CorrectionDocument | undefined
  >();
  const [latestAttendedClass, setLatestAttendedClass] = useState<BookingData>();
  const [topSelectedTabIndex, setTopSelectedTabIndex] = useState(3);
  const [activeLearningPath, setActiveLearningPath] = useState<
    undefined | LearningPathPersonal
  >();

  async function updateLatestCorrection(uid: string) {
    const allBookings = await getUserBookings(uid);
    const newLatestAttendedClass = getTheLatestAttendedBooking(allBookings);
    setLatestAttendedClass(newLatestAttendedClass);
    if (newLatestAttendedClass?.correctionID) {
      const correction = (await getCorrectionDocument(
        newLatestAttendedClass.correctionID,
      )) as CorrectionDocument;
      setLatestCorrection(correction);
    }
  }

  useEffect(() => {
    if (userData) {
      updateLatestCorrection(userData?.id);
      setActiveLearningPath(userData.activeLearningPath);
    }
  }, [userData]);

  const { setSubmitting } = props;

  return (
    <>
      {userData?.id ? (
        <div className="mt-2">
          <div className="grid lg:grid-cols-2 gap-2">
            {activeLearningPath ? (
              <div>
                <div className="md:mb-0 mb-2">
                  <ProfileOverview uid={userData?.id} disableLevel />
                </div>
                <LearningPathLevel
                  learningPathLevel={
                    activeLearningPath?.levels?.filter(
                      (level) => level.status === 'in-progress',
                    )?.[0]
                  }
                />
              </div>
            ) : (
              <ProfileOverview uid={userData?.id} />
            )}

            {!activeLearningPath ? (
              <div className="rounded-lg shadow w-full px-4 relative mb-2 bg-white md:pt-0 pt-4">
                <NoBorderTabs
                  tabs={['Feed', 'Ranking', 'Refer', 'Guest Pass']}
                  selectedIndex={topSelectedTabIndex}
                  onTabClicked={(i: number) => {
                    switch (i) {
                      case 0:
                        trackHomePageData(
                          'Clicked on the feed tab in homepage',
                        );
                        break;
                      case 1:
                        trackHomePageData(
                          'Clicked on the ranking tab in homepage',
                        );
                        break;
                      case 2:
                        trackHomePageData(
                          'Clicked on the refer tab in homepage',
                        );
                        break;
                      default:
                    }
                    setTopSelectedTabIndex(i);
                  }}
                />
                {
                  [
                    <ActivityFeedContainer
                      uid={userData?.id}
                      onActivityAvatarClicked={(profileID: string) => {
                        trackHomePageData(
                          'Clicked on profile on homepage activity feed',
                          {
                            profileID,
                          },
                        );
                      }}
                    />,
                    <RankingContainer uid={userData?.id} />,
                    <div className="pt-4">
                      <ReferFriendContainer />
                    </div>,
                    <GuestPassesContainer />,
                  ][topSelectedTabIndex]
                }
              </div>
            ) : (
              <div>
                {bookings?.length > 0 ? (
                  <div className="md:col-span-3 row-span-1 mb-2">
                    <NextClassContainer
                      bookings={bookings}
                      bookingsLoading={bookingsLoading}
                      userID={userData?.id}
                    />
                  </div>
                ) : null}
                <div className="rounded-lg shadow w-full px-4 relative mb-2 bg-white md:pt-0 pt-4 pb-2">
                  <NoBorderTabs
                    tabs={['Feed', 'Ranking', 'Refer', 'Guest Passes']}
                    selectedIndex={topSelectedTabIndex}
                    onTabClicked={(i: number) => {
                      switch (i) {
                        case 0:
                          trackHomePageData(
                            'Clicked on the feed tab in homepage',
                          );
                          break;
                        case 1:
                          trackHomePageData(
                            'Clicked on the ranking tab in homepage',
                          );
                          break;
                        case 2:
                          trackHomePageData(
                            'Clicked on the refer tab in homepage',
                          );
                          break;
                        default:
                      }
                      setTopSelectedTabIndex(i);
                    }}
                  />
                  {
                    [
                      <ActivityFeedContainer
                        uid={userData?.id}
                        onActivityAvatarClicked={(profileID: string) => {
                          trackHomePageData(
                            'Clicked on profile on homepage activity feed',
                            {
                              profileID,
                            },
                          );
                        }}
                      />,
                      <RankingContainer uid={userData?.id} />,
                      <div className="pt-4">
                        <ReferFriendContainer />
                      </div>,
                      <GuestPassesContainer />,
                    ][topSelectedTabIndex]
                  }
                </div>
                <BookingCalendar
                  bookings={bookings}
                  bookingsLoading={bookingsLoading}
                  userID={userData?.id}
                  subProductID={userData?.subProductID ?? ''}
                  onAction={() => {
                    setSubmitting(true);
                  }}
                  onActionComplete={() => {
                    setSubmitting(false);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      ) : null}
      <div
        className={cx(
          bookings?.length > 0 || latestCorrection?.corrections?.length
            ? 'md:row-span-2 md:grid md:grid-cols-5 md:grid-rows-1 grid-rows-3 gap-4'
            : '',
        )}
      >
        {(bookings?.length > 0 || latestCorrection?.corrections?.length) &&
        !activeLearningPath ? (
          <div className="md:col-span-3 row-span-1 md:mb-0 mb-4">
            {bookings?.length > 0 ? (
              <NextClassContainer
                bookings={bookings}
                bookingsLoading={bookingsLoading}
                userID={userData?.id}
              />
            ) : null}
            {latestAttendedClass &&
            latestCorrection?.corrections?.length &&
            latestCorrection?.formattedTranscriptArray?.length ? (
              <div className="my-2">
                {latestCorrection?.corrections?.length ? (
                  <NextClassTip userID={userData?.id} />
                ) : null}
                <ClassInsightContainer
                  bookingData={latestAttendedClass}
                  correctionData={latestCorrection}
                  onSeeMoreClick={() => {
                    trackHomePageClassInsight(
                      'Clicked see more on homepage class insight container',
                    );
                  }}
                  onRecordingPlayed={() => {
                    trackHomePageClassInsight(
                      'Clicked speaker icon on homepage class insight container',
                    );
                  }}
                />
              </div>
            ) : latestCorrection?.corrections?.length ? (
              <NextClassTip userID={userData?.id} />
            ) : null}
          </div>
        ) : null}

        {activeLearningPath ? null : (
          <div className="md:col-span-2 row-span-3">
            <BookingCalendar
              bookings={bookings}
              bookingsLoading={bookingsLoading}
              userID={userData?.id}
              subProductID={userData?.subProductID ?? ''}
              onAction={() => {
                setSubmitting(true);
              }}
              onActionComplete={() => {
                setSubmitting(false);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
