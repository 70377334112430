import { Fragment, useEffect, useRef, useState } from 'react';

import {
  addAvailableClassesToUser,
  BookingData,
  getClassSessionBookings,
  getClassSessionDocByStartMili,
  getClassWeekCleanSlide,
  getClassWeekTopic,
  getSessionBookers,
} from '../../../../firebase/subscription/subscription';
import Modal from '../../../../components/modal';
import VerticalMessageModal from '../../../../components/modals/verticalMessageModal/verticalMessageModal';
import moment from 'moment';
import { trackBookingAnalytics } from '../../../Booking/analytics';
import { getAllClasses } from '../../../../firebase/subscription/classes/classes';
import { createBookingDocument } from '../../../../firebase/subscription/bookings/bookings';
import { BookingCalendarModalLoader } from '../bookingCalendarLoader/bookingCalendarModalLoader';
import {
  BookingEventObject,
  EventBox,
} from '../../../Booking/bookingEventContainerStacked/bookingEventContainer';
import { maxClassSize } from '../../../../config/classes';
import momentTimezone from 'moment-timezone';
import { getIsClassRecommended } from '../../../../api/bookings';
import { monthInMili } from '../../../../usefuldata/mili';
import { cancelClassAtTime, resetClassAtTime } from '../../../../api/class';
import { checkIsAdmin } from '../../../../util/admin';
import cx from 'classix';
import HorizontalMessageModal from '../../../../components/modals/horizontalMessageModal/horizontalMessageModal';
import TextInput from '../../../../components/forms/textInput';
import PhoneInput from 'react-phone-number-input';
import SmallCardsRadio from '../../../../components/forms/smallCardsRadio/SmallCardsRadio';
import { GuestBookingInviteModal } from '../../../../components/modals/guestBookingInviteModal/guestBookingInviteModal';
import { PartialUserData } from '../../../../components/forms/loginregister';
import { Radio } from '../../../../components/forms/radio';
import { useSelector } from 'react-redux';
import { UserData } from '../../../../types/user';
import { filterValidPasses } from '../../../../util/guestPass';

const limit24hours = 1000 * 60 * 60 * 24;

export default function BookingModalStacked(props: {
  chosenDate: string;
  availableClassesNumber: number;
  isOpen: boolean;
  userID: string;
  userBookings: Array<BookingData>;
  onClose: Function;
  onSubmit: Function;
  onSuccess: Function;
  guest?: boolean;
}) {
  const {
    chosenDate,
    isOpen,
    onClose,
    availableClassesNumber,
    userBookings,
    userID,
    onSubmit,
    onSuccess,
    guest,
  } = props;
  const container = useRef<any>(null);
  const containerNav = useRef<any>(null);
  const containerOffset = useRef<any>(null);
  const [masterClassList, setMasterClassList] = useState<
    Array<BookingEventObject> | undefined
  >();
  const [classList, setClassList] = useState<
    Array<BookingEventObject> | undefined
  >();

  const [chosenScheduleIDs, setChosenScheduleIDs] = useState<Array<string>>([]);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [bookFor, setBookFor] = useState<string>('myself');
  const adminMode = checkIsAdmin(userID);

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  async function updateClasses() {
    const classes = await getAllClasses();
    setMasterClassList(classes);
  }

  function returnAlreadyBookedClass() {
    const chosenTitles =
      classList
        ?.map((c) => c.id)
        .map((id: string) => {
          const classData = classList?.filter((c) => c.id === id)?.[0];
          return classData?.title;
        }) ?? [];
    let bookedClasses: Array<string> = [];
    userBookings.forEach((b) => {
      if (chosenTitles.includes(b.title)) bookedClasses.push(b.title);
    });

    return bookedClasses;
  }

  // Book class
  async function onSubmitBooking(guest?: PartialUserData) {
    if (!classList) {
      return;
    }
    if (chosenScheduleIDs.length < 1) {
      setError('Please select at least 1 class before submitting.');
      return;
    }

    const chosenDateMoment = moment(chosenDate);

    // Chosen date is more than a month away
    if (chosenDateMoment.valueOf() > new Date().valueOf() + monthInMili) {
      setError('Please book classes happening within a month from now!');
      return;
    }

    const userId_ = guest?.id ?? userID;

    onSubmit();
    await Promise.all(
      chosenScheduleIDs.map(async (id: string) => {
        const currentBooking: BookingEventObject = classList.filter(
          (c: BookingEventObject) => c.id === id,
        )[0];
        const booking = await createBookingDocument(
          id,
          moment(currentBooking.startMili)
            .set('date', chosenDateMoment.date())
            .set('month', chosenDateMoment.month())
            .set('year', chosenDateMoment.year())
            .valueOf(), // Set it to chosen date
          currentBooking.durationMili,
          userId_,
          1,
        );
        trackBookingAnalytics('Booked a class on booking class modal', {
          userID: userID,
          bookingTitle: currentBooking.title,
        });
        return booking;
      }),
    );
    if (!guest)
      await addAvailableClassesToUser(userId_, -chosenScheduleIDs.length); // subtract necessary credits (Should prolly take place in the server)
    await updateClasses(); // Update classes so that rebooking is not possible
    setChosenScheduleIDs([]);
    setError(undefined);
    onSuccess();
    onClose(); // Close modal
  }

  function onChosenClass(id: string) {
    let newIDs: Array<string> = [...chosenScheduleIDs];
    if (bookFor === 'guest') {
      setChosenScheduleIDs([id]); // Only let choose 1
      return;
    }
    if (!newIDs.includes(id)) {
      newIDs.push(id);
    } else {
      newIDs.splice(newIDs.indexOf(id));
    }
    setChosenScheduleIDs(newIDs);
  }

  async function onChosenDateChanged() {
    setLoading(true);
    setChosenScheduleIDs([]); // Reset
    if (masterClassList) {
      let newClass = masterClassList.filter((c: BookingEventObject) => {
        return (
          moment(c.startMili).day() === moment(chosenDate).day() &&
          (c.recurring ||
            moment(c.startMili).set({ h: 0, m: 0, s: 0, ms: 0 }).valueOf() === // Check if this isn't a recurring class then it has to be happening today exactly
              moment(chosenDate).set({ h: 0, m: 0, s: 0, ms: 0 }).valueOf())
        );
      });

      newClass = await Promise.all(
        newClass.map(async (c) => {
          const newClassObject = { ...c };
          const chosenMoment = moment(chosenDate);

          let sessionMili = moment(c.startMili)
            .set('month', chosenMoment.month())
            .set('date', chosenMoment.date())
            .set('year', chosenMoment.year())
            .valueOf();

          if (newClassObject.id) {
            const topic = await getClassWeekTopic(
              newClassObject.id,
              sessionMili,
            );
            const slide = await getClassWeekCleanSlide(
              newClassObject.id,
              sessionMili,
            );

            const time = moment(sessionMili);
            const timeZone = momentTimezone.tz.guess();
            console.log(timeZone);
            const timeChange =
              moment(sessionMili).utcOffset() - moment(c.startMili).utcOffset();
            const timeChangeUS =
              momentTimezone(sessionMili)
                .tz('America/Mexico_City')
                .utcOffset() -
              momentTimezone(c.startMili).tz('America/Mexico_City').utcOffset();

            if (
              timeZone === 'America/Los_Angeles' ||
              timeZone === 'America/New_York' ||
              timeZone === 'America/Chicago' ||
              timeZone === 'America/Vancouver' ||
              timeZone === 'America/Denver' ||
              timeZone === 'America/Edmonton' ||
              timeZone === 'America/Phoenix' ||
              timeZone === 'America/Winnipeg' ||
              timeZone === 'America/Indianapolis' ||
              timeZone === 'America/Montreal' ||
              timeZone === 'America/Puerto_Rico'
            ) {
              // sessionMili += (timeChange - timeChangeUS) * 60 * 1000;
              // time.add((timeChange / 60 - timeChangeUS / 60), 'h');
              sessionMili += (timeChange - timeChangeUS) * 60 * 1000;
              time.add(timeChange / 60 - timeChangeUS / 60, 'h');
            }
            const bookings = await getClassSessionBookings(
              newClassObject.id,
              sessionMili,
            );

            const recommneded = (await getIsClassRecommended(userID, c.id))
              ?.recommended;

            const sessionDoc = await getClassSessionDocByStartMili(
              newClassObject.id,
              sessionMili,
            );
            const sessionID = sessionDoc?.id;
            const teacher = sessionDoc?.teacher || c?.teacher;

            const bookers = sessionID ? await getSessionBookers(sessionID) : [];
            let overlaps = false;
            let alreadyBooked = returnAlreadyBookedClass();
            if (alreadyBooked.includes(topic) && bookFor === 'myself') {
              newClassObject.alreadyBooked = true;
            }

            for (let i = 0; i < userBookings.length; i++) {
              // If this event overlaps with some bookings
              if (
                time.valueOf() >= userBookings[i].startMili &&
                time.valueOf() <
                  userBookings[i].startMili + userBookings[i].durationMili &&
                bookFor === 'myself'
              ) {
                overlaps = true;
                break;
              }
            }
            newClassObject.startMili = time.valueOf();
            if (
              newClassObject?.reschedule?.[newClassObject.startMili] !==
              undefined
            ) {
              if (newClassObject?.reschedule?.[newClassObject.startMili] > 0)
                newClassObject.startMili =
                  newClassObject?.reschedule?.[newClassObject.startMili];
              // rescheduled schedule
              else newClassObject.disabled = true;
            }

            newClassObject.recommended = recommneded;
            newClassObject.bookings = bookings.filter((b) => !b.canceled);
            newClassObject.bookerList = bookers;
            newClassObject.teacher = teacher;
            newClassObject.title = topic;
            newClassObject.cleanSlide = slide;
            newClassObject.classSessionID = sessionID;
            newClassObject.disabled =
              newClassObject.canceled ||
              newClassObject.disabled ||
              (new Date().valueOf() + limit24hours >=
                newClassObject.startMili &&
                newClassObject.bookings?.length === 0) || // If there's already a booking and happens outside of 24 hours window
              time.isBefore(moment()) ||
              overlaps ||
              newClassObject.startMili >
                moment(chosenDate).set('hour', 24).valueOf();
          }
          return newClassObject; // Clone session object to avoid altering the main list
        }),
      );
      newClass?.sort((a, b) => {
        return a.startMili - b.startMili;
      });
      setClassList(newClass);
      setLoading(false);
    }
  }

  useEffect(() => {
    onChosenDateChanged();
  }, [masterClassList, chosenDate, isOpen, bookFor]); // Fix maybe?

  useEffect(() => {
    updateClasses();
    // Set the container scroll position based on the current time.
    const currentMinute = new Date().getHours() * 60;
    if (container?.current && containerNav.current && containerOffset.current) {
      container.current.scrollTop =
        ((container.current.scrollHeight -
          containerNav.current.offsetHeight -
          containerOffset.current.offsetHeight) *
          currentMinute) /
        1440;
    }
  }, []);

  useEffect(() => {
    console.log(classList);
  }, [classList]);

  const validPasses = filterValidPasses(userData?.guestPasses ?? []);

  const canBook =
    (bookFor === 'myself' &&
      chosenScheduleIDs.length <= availableClassesNumber) ||
    (bookFor === 'guest' && chosenScheduleIDs.length <= validPasses.length);

  return (
    <>
      <GuestBookingInviteModal
        userID={userID}
        open={openInviteModal}
        onClose={() => {
          setOpenInviteModal(false);
        }}
        onSubmit={(guestData: PartialUserData) => {
          onSubmitBooking(guestData);
        }}
      />
      <VerticalMessageModal
        mainMessage=""
        buttonText={canBook ? `Submit booking` : ''}
        // sparkleButtonOnClick={() => {
        //   if (chosenScheduleIDs.length < 1) {
        //     setError('Please select at least 1 class before submitting.');
        //     return;
        //   }

        //   const chosenDateMoment = moment(chosenDate);

        //   // Chosen date is more than a month away
        //   if (chosenDateMoment.valueOf() > new Date().valueOf() + monthInMili) {
        //     setError('Please book classes happening within a month from now!');
        //     return;
        //   }

        //   setOpenInviteModal(true);
        // }}
        // sparkleButtonText="Submit booking for a friend"
        subMessage=""
        icon="clock"
        theme="default"
        isOpen={isOpen}
        onButtonClick={() => {
          trackBookingAnalytics(
            'Clicked submit booking on booking class modal',
            {
              userID: userID,
              bookFor: bookFor,
            },
          );
          if (bookFor === 'myself') {
            onSubmitBooking();
          } else if (bookFor === 'guest') {
            if (chosenScheduleIDs.length < 1) {
              setError('Please select at least 1 class before submitting.');
              return;
            }

            const chosenDateMoment = moment(chosenDate);

            // Chosen date is more than a month away
            if (
              chosenDateMoment.valueOf() >
              new Date().valueOf() + monthInMili
            ) {
              setError(
                'Please book classes happening within a month from now!',
              );
              return;
            }

            setOpenInviteModal(true);
          }
        }}
        onCloseModal={() => {
          onClose();
          setError(undefined);
          setChosenScheduleIDs([]);
        }}
        stayOpenOnPrimaryClick
      >
        <div className="flex md:max-h-[700px] max-h-[500px] flex-col">
          <div className="bg-white w-full text-center">
            Classes available {moment(chosenDate).format('MMMM Do, YYYY')}
          </div>
          {!loading ? (
            <div className="mt-6 overflow-y-scroll py-2">
              {classList?.map((c: BookingEventObject) => {
                console.log(c);
                const time = moment(c.startMili)
                  .set('month', moment(chosenDate).month())
                  .set('date', moment(chosenDate).date())
                  .set('year', moment(chosenDate).year());

                const dayMisMatch = time.day() !== moment(c.startMili).day();

                const chosen =
                  c?.id !== undefined && chosenScheduleIDs.includes(c?.id);
                const full =
                  c?.bookings !== undefined &&
                  c.bookings.length > maxClassSize &&
                  !c.noCap;
                const disabled =
                  c?.disabled || full || c.canceled || dayMisMatch;
                const sessionCanceled = c?.reschedule?.[c.startMili] === 0;

                return (
                  <>
                    {adminMode ? (
                      <div
                        className={cx(
                          sessionCanceled
                            ? 'bg-blue-500 text-blue-800 hover:bg-blue-300 hover:text-blue-600'
                            : 'bg-red-500 text-red-800 hover:bg-red-300 hover:text-red-600',
                          'rounded-md cursor-pointer max-w-[150px] mx-auto',
                        )}
                        onClick={async () => {
                          setLoading(true);
                          if (!sessionCanceled)
                            await cancelClassAtTime(c?.id, c.startMili);
                          else await resetClassAtTime(c?.id, c.startMili);
                          await updateClasses();
                          setLoading(false);
                        }}
                      >
                        {sessionCanceled ? `Uncancel class` : `Cancel class`}
                      </div>
                    ) : null}
                    <EventBox
                      {...c}
                      key={c.id + c.startMili}
                      recommended={c?.recommended}
                      disabled={disabled}
                      full={full}
                      chosen={chosen}
                      disableColumn
                      enablePreview={c?.cleanSlide !== undefined}
                      onPreviewClick={() => {
                        window.open(
                          c?.classSessionID
                            ? `${window.location.origin}/class-info?cid=${c?.classSessionID}`
                            : c?.cleanSlide,
                          '_blank',
                        );
                        trackBookingAnalytics(
                          'Clicked preview slide on booking calendar modal',
                          {
                            classID: c?.id,
                          },
                        );
                      }}
                      onClick={() => {
                        if (c?.id && !disabled) {
                          trackBookingAnalytics(
                            'Selected a class on booking class modal',
                            {
                              userID: userID,
                              classID: c?.id,
                              bookingTitle: c?.title,
                              bookingStartMili: time.valueOf(),
                              selectedDate: chosenDate,
                            },
                          );
                          onChosenClass(c.id);
                        }
                      }}
                    />
                  </>
                );
              })}
            </div>
          ) : (
            <BookingCalendarModalLoader />
          )}

          {!canBook ? (
            <div className="text-red-warning">
              {bookFor === 'myself' && availableClassesNumber === 0
                ? `You don't have any class credits left.`
                : bookFor === 'guest' && validPasses.length === 0
                ? `You don't have any unused guest pass.`
                : `You can only book up to ${
                    bookFor === 'myself'
                      ? availableClassesNumber
                      : validPasses.length
                  } class${
                    (bookFor === 'myself'
                      ? availableClassesNumber
                      : validPasses.length) > 1
                      ? 'es'
                      : 's'
                  }!`}
            </div>
          ) : null}
          {error ? <div className="text-red-warning">{error}</div> : null}
          <div className="text-left">
            <Radio
              options={[
                { value: 'myself', text: 'Myself' },
                { value: 'guest', text: 'Guest' },
              ]}
              id=""
              label="Reserve For"
              value={bookFor}
              onChange={(v: string | undefined) => {
                if (v) setBookFor(v);
              }}
            />
          </div>
        </div>
      </VerticalMessageModal>
    </>
  );
}
